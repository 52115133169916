.SmeContainer {
  background: #ffffff;
  text-align: center;
  box-shadow: 0px 4px 4px rgb(0 0 0 / 25%);
  border-radius: 10px;
  padding: 10rem 40px;
}
.sme_txt {
  font-weight: 700;
  /* font-size: 80px; */
  font-size: 4.4vw;
}
.big-bussiness {
  margin-top: 10rem;
}

.wwyDesc {
  width: 90%;
  margin: auto;
  display: flex;
  justify-content: center;
  margin-top: 70px;
}
.wwyContent {
  width: calc(33.3% - 10px);
  padding: 30px 20px;
  background: #ffffff;
  margin: 0px 10px;
  text-align: start;
  border-radius: 10px;
  background: #ffffff;
  mix-blend-mode: darken;
  box-shadow: 0px 4px 4px rgb(0 0 0 / 25%);
  border-radius: 10px;
}

.wwyexp {
  color: #676767 !important;
  font-weight: 400;
  margin-top: 10px;
  font-size: 16px;
  color: #676767;
}
