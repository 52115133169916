.big-heading {
  font-size: var(--big-font);
  font-weight: var(--extra-bold);
  color: var(--dark-blue);
  margin-top: 70px;
}

.input-field,
.text-area {
  padding: 50px 70px;
  border: none;
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.05);
  color: var(--grey) !important;
}

/* .input-field:focus,
.textarea:focus {
  outline: none;
  transition: 1s;
  box-shadow: none;
  border: 1px solid #f6f6f6;
}

.text-area {
  resize: none;
  height: 350px;
} */

.pattern {
  position: absolute;
  top: 80px;
  right: 0;
  z-index: -1;
}

.push {
  margin-top: 40px;
}

.textarea {
  height: 250px;
  border: none;
  outline: none;
  resize: none;
  color: #929aa7 !important;
}

.textarea:hover,
.textarea:active {
  border: none;
  outline: none;
}

.shadow-wrapper-textarea {
  background-color: #fff;
  box-shadow: 10px 10px 20px rgba(56, 39, 39, 0.08),
    -10px -10px 20px rgba(56, 39, 39, 0.08);
  padding: 20px;
  margin-top: 20px;
  transition: box-shadow 0.3s ease-in-out;
  display: block;
}

.shadow-wrapper:hover {
  box-shadow: 10px 10px 20px rgba(56, 39, 39, 0.05),
    -10px -10px 20px rgba(56, 39, 39, 0.05);
}

@media screen and (max-width: 900px) {
  .input-field,
  .text-area {
    padding: 20px 20px;
    border: 1px solid #ddd;
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.05);
    color: var(--grey) !important;
    font-size: 16px;
    line-height: 24px;
  }
  .text-area {
    height: 200px;
  }
  .big-heading {
    font-size: 24px;
    line-height: 30px;
    font-weight: var(--extra-bold);
    color: var(--dark-blue);
    margin-top: 30px !important;
  }
}
