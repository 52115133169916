.featureItem1 {
  background: #ffffff;
  box-shadow: 0px 22px 70px rgba(88, 99, 246, 0.12);
  border-radius: 14.9813px;
  padding: 40px 30px;
  position: relative;
  margin-bottom: 20px;
  font-family: "Sora";
}
.item1Title {
  font-size: 25px;
  font-weight: 600;
  margin: 0;
}

.item1Desc {
  font-size: 17px;
  font-weight: 500;
  column-gap: 40px;
  font-weight: 400 !important;
  color: #676767;
  margin: 0;
}

.item1Icon {
  /* display: none; */
  position: absolute;
  left: 70.38%;

  top: -8.64%;
}

@media screen and (max-width: 900px) {
  .item1Title {
    font-size: 20px;
  }

  .item1Desc {
    font-size: 15px;
  }
  .item1Icon {
    width: 120px;
    top: 10%;
    height: 120px;
  }
  .image {
    width: 100%;
    height: 100%;
  }
}
