/* @import "./variables"; */
.auth {
  width: 100%;
  height: 100%;
  background: #274b89;
  position: absolute;
}
.auth a {
  color: #274b89;
}
.auth .left {
  background: url("../assets/auth-bg.svg") no-repeat;
  background-size: 100%;
  background-position: 0%;
  background-position-y: 0;
  height: 100%;
  position: absolute;
  left: 0;
  padding-top: 100px;
  color: #fff;
  padding-left: 60px;
  padding-right: 60px;
  overflow-y: auto;
}
.auth .left .welcome {
  font-size: 47.2431px;
  line-height: 64px;
  text-align: center;
  color: #fff;
  text-align: center;
}
.auth .left .spottr {
  font-weight: bold;
  font-size: 95.7132px;
  line-height: 131px;
  text-align: center;
  color: #fff;
  margin-bottom: 56px;
}
.auth .left .body .dis-title {
  font-family: Nunito;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 25px;
  color: #ecf7ff;
  margin-right: 13px;
}
.auth .left .body p {
  font-family: Nunito;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 158.8%;
  padding-right: 2.5rem;
  color: #c2e0ff;
}
.auth .right {
  height: 100%;
  position: absolute;
  background-color: #fff;
  right: 0;
  padding: 65px 77px;
}
.auth .right .top-navbar {
  margin-bottom: 36px;
  line-height: 50px;
}
.back-arrow {
  line-height: 20px;
}
.back-text {
  line-height: 50px;
  font-size: 15px;
}
.auth .right .top-navbar .btn-link {
  font-size: 15px;
  line-height: 20px;
  color: #274b89;
  text-decoration: none;
}
.auth .right .top-navbar .btn-link .fas {
  margin-right: 21px;
}
.auth .right .title {
  font-weight: bold;
  font-size: 24px;
  line-height: 33px;
  margin-bottom: 31px;
  color: #274b89;
}
.auth .right .terms {
  padding: 16px 3.5rem;
  text-align: center;
  margin-bottom: 65px;
}
.auth .right .password-validation {
  margin-bottom: 88px;
}
.auth .right .password-validation span {
  display: block;
  font-weight: normal;
  font-size: 14px;
  line-height: 158.8%;
}
.auth .btn-forgetpassword {
  font-size: 14px;
  line-height: 16px;
  text-align: right;
  float: right;
  padding-right: 0;
  color: #274b89;
}
.auth .login-with .buttons {
  margin-top: 45px;
  margin-bottom: 45px;
}
.auth .login-with .btn {
  background: #fff;
  border: 1px solid #cbcbcb;
  border-radius: 4px;
  padding: 15px 20px;
  font-weight: bolder;
}
.auth .login-with .btn.facebook {
  font-size: 16px;
  margin-right: 21px;
  text-align: center;
  color: #4267b2;
}
.auth .login-with .btn.google {
  font-size: 16px;
  line-height: 20px;
  color: #000;
}
.auth .login-with .btn.google .icon {
  margin-right: 14px;
  vertical-align: middle !important;
}
.auth .login-with .btn .icon {
  width: 23px;
}
.auth .btn-submit {
  background: #274b89;
  box-shadow: 0px 9px 20px rgba(171, 180, 189, 0.148862);
  border-radius: 4px;
  width: 100%;
  padding: 18px;
}
.bottom {
  margin-top: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.bottom p {
  margin-bottom: 0;
  margin-right: 1rem;
}
.bottom p .fas {
  margin-left: 1rem;
}
.btn-auth {
  font-family: "Roboto";
  font-style: normal;
  font-weight: normal;
  font-size: 14.2703px;
  line-height: 17px;
  text-align: center;
  color: #274b89;
  background: #fff;
  border-radius: 26.1622px;
  padding: 13px 33px;
}
.with-icon {
  position: relative;
}
.with-icon .icon {
  position: absolute;
  right: 20px;
  top: 22px;
  color: #c4c4c4;
  font-size: 12px;
}
.form-control {
  background-color: #f8f8f8;
  padding: 25px;
  color: #c4c4c4;
  border: 0;
  outline: #f8f8f8;
}
.form-control:focus {
  border: 1px solid #274b89;
}
@media (max-width: 1024.98px) {
  .auth .left {
    padding-left: 16px;
    padding-right: 16px;
  }
  .auth .right {
    padding-left: 16px;
    padding-right: 16px;
  }
}
@media (max-width: 768.98px) {
  .auth .left {
    padding-left: 60px;
    padding-right: 60px;
  }
  .auth .right {
    padding-left: 60px;
    padding-right: 60px;
  }
}
@media (max-width: 480.98px) {
  .auth .left {
    padding-left: 16px;
    padding-right: 16px;
  }
  .auth .right {
    padding-left: 16px;
    padding-right: 16px;
  }
}
