.navBg {
  background-color: #102954 !important;
}
.sptr-navbar {
  padding-bottom: 40px;
  padding-top: 40px;
  background-color: #102954 !important;
  display: flex;
  justify-content: space-between;
}

.link {
  padding: 15px 20px;
}

.sign-up {
  background-color: white;
  padding: 15px;
  border-radius: 40px;
  font-weight: bold;
}

.sign-up .link {
  color: var(--dark-blue) !important;
}

.login {
  padding: 15px;
  color: white;
}

.login:hover {
  padding: 15px;
  color: #274b89;
  text-decoration: none !important;
}

.nav-links {
  line-height: 53px;
  /* display: none; */
}

.nav-md {
  display: none;
}

/* .nav-items {
  display: none;
} */

.login,
.sign-up {
  text-decoration: none !important;
}
.svgBox {
  height: 40px;
  width: 40px;
  /* padding: 5px; */
}
.svgs {
  width: 100%;
  height: 100%;
  fill: #ffffff;
}
@media screen and (max-width: 900px) {
  .nav-links {
    display: none !important;
  }
  .logo {
    height: 50px !important;
  }
  .nav-md {
    display: inline-block;
  }
  .opacity-0 {
    opacity: 1;
    transition: all 0.5s ease-in-out;
  }
  .nav-items {
    display: block;
    position: absolute;
    background: 
    #102954;
    height: 100%;
    z-index: 1;
    top: -400 !important;
    width: 100%;
    display: none;
    margin-left: -15px;
   
  }
  .slideIn{
    animation-name: moveIn;
    animation-duration: 1s;
    animation-timing-function: ease-in;
  }
  .slideOut{
    animation-name: moveOut;
    animation-duration: 1s;
    animation-timing-function: ease-out;
  }
  .login,
  .sign-up {
    display: none;
  }
  .nav-items.active {
    display: block;
    opacity: 1 !important;
    transition: all 2s ease-in-out;
    top: 235 !important;
    z-index: 99999;
  }
  
}

@keyframes moveIn {
  from {
    opacity: 0;
    transform: translateX(100vw);
  }
  to {
    opacity: 1;
    transform: translateX(0px);
  }
}
@keyframes moveOut {
  from{
    /* opacity: 1; */
    transform: translateX(100vw);
  }
  to{
    /* opacity: 0; */
    transform: translateX(-100vw);
  }

}