@import url("https://fonts.googleapis.com/css2?family=Nunito:wght@400;700;800;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Sora:wght@200;300;400;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poiret+One&display=swap");

body {
  font-family: "Nunito", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0;
  padding: 0;
  user-select: none;
  font-family: "Nunito", sans-serif !important;
  overflow-x: hidden !important;
}

:root {
  --regular: 400;
  --bold: 700;
  --extra-bold: 800;
  --black: 900;
  --dark-blue: #102954;
  --darker-bg: #061e48;
  --yellow: #ffb211;
  --lighter-blue: #ecf7ff;
  --powder-blue: #c2e0ff;
  --grey: #929aa7;
  --light-grey: #555555;
  --big-font: 60px;
  --mid-font: 48px;
  --medium-font: 35px;
  --small-font: 24px;
  --smaller-font: 18px;
}

.major-container {
  max-width: 100% !important;
  margin: 0 auto;
}

.opacity-0 {
  opacity: 0;
}

.evenodd {
  display: none !important;
}

.blue {
  color: var(--dark-blue);
}

.reg24 {
  font-size: var(--small-font);
  font-weight: var(--regular);
  line-height: 36px;
}

.xb-51 {
  font-weight: var(--extra-bold);
  font-size: var(--mid-font);
  line-height: 51px;
  color: var(--dark-blue);
}

.reg48 {
  font-size: 30px;
  font-weight: var(--regular);
  line-height: 56px;
}

.grey24 {
  font-size: var(--small-font);
  font-weight: var(--regular);
  color: var(--grey);
  line-height: 36px;
}

.bold24 {
  font-size: var(--small-font);
  font-weight: var(--bold);
  line-height: 36px;
  color: var(--dark-blue);
}

.bold60 {
  font-size: var(--big-font);
  font-weight: var(--bold);
  line-height: 36px;
  color: var(--dark-blue);
}

.bold18 {
  font-size: var(--smaller-font);
  font-weight: var(--bold);
  color: var(--light-grey);
  /* display: block !important; */
}

.bold-38 {
  font-size: var(--medium-font);
  font-weight: var(--bold);
  color: var(--dark-blue);
  /* margin-left: 10px; */
}
.bold18:hover {
  color: #000;
}

.button {
  display: inline;
  margin-top: -500px;
}

.relative {
  position: relative;
}

.absolute {
  position: absolute;
}

.flex {
  display: flex !important;
}
.w80 {
  width: 80%;
}

.white-bg {
  background-color: #fff;
}

.accordion__button:before {
  display: none !important;
}

.plus-img {
  height: 20px;
}

.faq-header {
  color: var(--dark-blue);
  font-size: var(--smaller-font);
  font-weight: var(--bold);
}

.form-text {
  font-size: 12px;
  line-height: 18px;
  color: #274b89;
}

.create-account-title {
  padding-bottom: 25px;
  font-size: 16px;
  color: #000;
  font-weight: bold;
}

.main-font {
  font-size: 20px;
  padding: 100px 0 !important;
}

.mobile-links {
  display: none;
}

@media screen and (max-width: 900px) {
  .reg24 {
    font-size: 18px;
    line-height: 30px;
  }
  .mobile-links {
    display: block !important;
    padding: 20px 10px;
    color: #ffffff !important;
  }
  .mobile-links a,
  .mobile-links a:hover {
    color: #ffffff !important;
  }
  .th-caption {
    margin-top: 15px;
  }
  .w85 {
    width: 100% !important;
  }
  .xb-51 {
    font-weight: var(--extra-bold);
    font-size: 20px;
    line-height: 24px;
    color: var(--dark-blue);
  }
  .new-heading {
    font-size: 24px;
    line-height: 28px;
  }
}

@media screen and (max-width: 2500px) {
  .major-container {
    max-width: 2500px !important;
    margin: 0 auto;
  }
}
