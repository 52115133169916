.paymentBg {
  /* display: none; */
  background-color: #f4f9ff;
  /* padding: 100px 0 0 0; */
  height: 800px;
}
.ovalPayment {
  position: absolute;
  left: 57.24%;
  /* z-index: -100; */
  top: 0px;
}

.paymentHeading {
  text-align: center;
  width: 100%;
  line-height: 1.2;
  margin: 0 auto;
  position: relative;
}
.paymentText {
  width: 100%;
  position: relative;
}
.zIndex {
  z-index: 2;
}
.getText {
  width: 100%;
  color: #384c6d;
  font-weight: 700 !important;
  font-size: 50px !important;
  margin: 0 auto;
  padding: 20px 0;
  font-family: "Sora";
  /* position: relative; */
}
.getText2 {
  font-size: 32px;
  width: 100%;
  margin: 0 auto;
  color: #676767;
}

.union {
  position: absolute;
  top: 0;
}
.rec {
  position: absolute;
  right: 10px;
  bottom: 0;
}

.wallet {
  height: 80%;
}

.bigcir {
  bottom: -10px;
  right: -5rem;
  /* left: -20px; */
}
.bacode {
  left: 10%;
  top: 130px;
}
.phone {
  left: -30%;
  top: 160px;
}
.laptop {
  left: 18%;
  top: 190px;
}
@media screen and (max-width: 1100px) {
  .paymentText {
    height: 220px;
  }
  .phone {
    left: -20%;
  }
  .laptop {
    left: 18%;
  }
}
@media screen and (max-width: 990px) {
  .paymentText {
    height: 150px;
  }
  .p50 {
    padding: 50px;
  }
  .paymentBg {
    height: 1200px;
  }
  .ovalPayment {
    display: none;
  }
  .wallet {
    width: 100%;
    height: 70%;
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
  .bigcir {
    height: 100%;
    width: 100%;
    bottom: 0;
    right: 0;
  }
  .bacode {
    transform: translate(-50%, -50%);
    top: 50%;
    left: 50%;
  }
  .phone {
    height: 350px;
    width: 400px;
    left: 0;
    top: 230px;
  }
  .fit {
    height: 100%;
    width: 100%;
  }
  .laptop {
    left: 27%;
    top: 250px;
  }
}

@media screen and (max-width: 800px) {
  .getText {
    font-weight: 400 !important;
  }
  .laptop {
    height: 250px;
    width: 430px;
  }

  .phone {
    height: 270px;
    width: 400px;
    left: -80px;
    top: 230px;
  }
}

@media screen and (max-width: 600px) {
  .paymentBg {
    height: 1000px;
    padding: 50px 0 0 0;
  }
  .phone {
    height: 200px;
    width: 240px;
    left: -55px;
    top: 250px;
  }
  .laptop {
    height: 185px;
    width: 255px;
    left: 27%;
    top: 270px;
  }
}

@media screen and (max-width: 500px) {
  .paymentText {
    height: 200px;
  }
  .p50 {
    padding: 0;
  }
  .paragraghBold {
    margin: 50px 0;
  }
}
